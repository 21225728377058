import React from "react";
import Clients from "./Clients";
import "../style.css";
import RequestCall from "./RequestCall";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[180px] md:h-[200px] product"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mx-auto">
            <p className="text-2xl md:text-4xl font-sans text-gray-300 md:text-white font-bold">
              PES Electrical Provide
            </p>
          </div>
          <div className="w-[100%] md:w-[65%] mx-auto ">
            <p className="text-xl md:text-2xl font-sans italic text-gray-300 md:text-white font-bold">
              BEST Customized solution for
            </p>
          </div>
          <div className="w-[100%] md:w-[50%] mx-auto ">
            <p className="text-2xl md:text-4xl font-sans text-gray-300 md:text-white font-bold">
              Prepaid & Postpaid Metering
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className=" block w-[100%] h-[350px] md:h-[150px] producttow"></div>
        <div className="absolute w-[100%] top-0 md:left-8 left-0 px-5">
          <div className="w-[90%] mt-5 mb-5 mx-auto h-auto md:flex gap-12">
            <div className="h-auto text-center mx-auto w-[250px]">
              <p className="mt-2 text-lg md:text-4xl text-pink-700 font-serif font-semibold">
                105000+
              </p>
              <p className="mt-2 text-lg md:text-md font-serif font-semibold">
                Homes Installation Base
              </p>
            </div>
            <div className="h-auto text-center mx-auto w-[250px]">
              <p className="mt-2 text-lg md:text-4xl text-pink-700 font-serif font-semibold">
                3000 Crore+
              </p>
              <p className="mt-2 text-lg md:text-md font-serif font-semibold">
                Revenue Managed
              </p>
            </div>
            <div className="h-auto text-center mx-auto w-[250px]">
              <p className="mt-2 text-lg md:text-4xl text-pink-700 font-serif font-semibold">
                2009 MW+
              </p>
              <p className="mt-2 text-lg md:text-md font-serif font-semibold">
                Power Distribution Managed
              </p>
            </div>
            <div className="h-auto text-center mx-auto w-[250px]">
              <p className="mt-2 text-lg md:text-4xl text-pink-700	 font-serif font-semibold">
                2
              </p>
              <p className="mt-2 text-lg md:text-md font-serif font-semibold">
                User Mobile Application
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[500px]" src="../image/pro1.JPG" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-8">
          <p className="text-3xl font-sans font-bold">
            <u>Three Phase Meter</u>
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            AVAILABLE IN :-
          </p>
          <p className="text-lg mt-5 font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Dual</span> Source Panel Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Dual</span> Source Prepaid Energy Meter
            Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Dual</span> Source KWH Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Dual</span> Source Multifunction Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Single</span> Source Panal Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Single</span> Source Prepaid Energy
            Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Single</span> Source KWH Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Single</span> Source Multifunction Meter
          </p>
          <div className="md:flex justify-between gap-10">
            <div
              className="box-1 mt-5 md:mt-10"
              onClick={() => navigate("/threephase")}
            >
              <div class="btn btn-one">
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="mt-3 w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img
            className="h-[500px] w-[460px]"
            src="../image/pro2.JPG"
            alt="..."
          />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-5 md:mt-10">
          <p className="text-3xl font-sans font-bold">
            <u>Single Phase Meter</u>
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            AVAILABLE IN :-
          </p>
          <p className="text-lg mt-5 font-sans">
            <i class="far fa-caret-square-right"></i> Single Phase{" "}
            <span className="font-bold">Dual</span> Source Prepaid Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Single Phase{" "}
            <span className="font-bold">Dual</span> Source KWH Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Single</span> Source Prepaid Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Three Phase{" "}
            <span className="font-bold">Single</span> Source KWH Meter
          </p>
          <div className="md:flex justify-between gap-10">
            <div className="box-1 mt-5 md:mt-10">
              <div
                className="btn btn-one"
                onClick={() => navigate("/singlephase")}
              >
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="mt-3 w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img
            className="h-[500px] w-[460px]"
            src="../image/pro3.jpeg"
            alt="..."
          />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-5 md:mt-10">
          <p className="text-3xl font-sans font-bold">
            <u>WIFI SMART DG-MAINS PLUG</u>
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            An Innovative product :-
          </p>
          <p className="text-lg mt-5 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Switch on/off your
            appliances from anywhere.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Switch off your load when
            power source feed is DG.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Schedule your Plug on/off
            anytime as per your choice.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Easy to configure.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Easy to install.
          </p>
          <div className="md:flex justify-between gap-10">
            <div className="box-1 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/dgmain")}>
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="flex justify-center items-center w-full mt-5">
        <p className="text-2xl md:text-3xl font-sans font-bold ">
          <u>Energy Management Communication Hardware</u>
        </p>
      </div>
      <div className="w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div className="md:mt-10">
          <img className="h-[400px]" src="../image/pro4.jpeg" alt="..." />
        </div>
        <div className="mt-5 md:mt-10">
          <img className="h-[400px]" src="../image/pro5.jpeg" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto">
          <p className="text-lg mt-4 font-sans font-semibold">
            AVAILABLE IN :-
          </p>
          <p className="text-lg mt-5 font-sans font-semibold">
            1. DTRH :- DATA TRANSFER AND RECEIVING HUB
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> Convert signal mode from
            RS485 TO RS232 and vice-versa
          </p>
          <p className="text-lg font-sans mt-5 font-semibold">
            2. GPRS Modem :-
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> Compact size.
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> Embedded web server for
            easy configration.
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> Modbus/ MQTT protocol.
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> Configurable RS485
            baudrate, priority and stop bits.
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> Completely isolated in
            both RS485 and RJ45.
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> One touch recovery.
          </p>
          <p className="text-lg mt-2 px-5 font-sans">
            <i class="far fa-caret-square-right"></i> LED indications for easy
            setup and trouble shooting.
          </p>
          <div className="md:flex justify-between gap-10">
            <div className="box-1 mt-5 md:mt-10">
              <div
                class="btn btn-one"
                onClick={() => navigate("/communication")}
              >
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="flex justify-center items-center w-full mt-5">
        <p className="text-2xl md:text-3xl font-sans font-bold ">
          <u>Management Software</u>
        </p>
      </div>
      <div className="w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div className="md:mt-20">
          <img className="h-[400px]" src="../image/pro6.jpeg" alt="..." />
        </div>
        <div className="mt-5 md:mt-20">
          <img className="h-[400px]" src="../image/mile7.jpeg" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto">
          <p className="text-lg mt-4 font-sans font-semibold">
            AVAILABLE IN :-
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Online Customer login
            portal/ Mobile app login.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> User can get balance
            credit through any of the interface.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> (Mobile / Web portal) or
            through app.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Remote Display.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Real Time data monitoring.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Recharge slip, recharge
            history for the selected data range.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Monthly billing with
            breakup of EB/DG energy bills and maintenance bill.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Running hours of DG and
            Mains Supply is provided.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Cheque Reconciliation.
          </p>
          <p className="text-lg mt-5 font-sans font-bold">
            ALERTS OVER EMAIL/SMS :-
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Sending SMS to the users
            during power outage.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Get alerts for activities
            like Account recharge, Recharge status.
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Low Credits, Overload,
            Overvoltage, etc.
          </p>
          <div className="md:flex justify-between gap-10">
            <div className="box-1 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/mansoftware")}>
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="mt-3 w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[500px]" src="../image/mile10.JPG" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-8">
          <p className="text-3xl font-sans font-bold">
            <u>HOME DISPLAY UNIT</u>
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            AVAILABLE IN :-
          </p>
          <p className="text-lg mt-5 font-sans">
            <i class="far fa-caret-square-right"></i> Allowing customer to
            monitor consumption.
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Eligant design and no
            separate power is required. Meter
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Display all parameters
            including balance.
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Display blinks and buzzer
            beeps in frequent interval to indicate low balance or at the time of
            power source change.
          </p>
          <p className="text-lg mt-5 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Types of Home Display :
          </p>
          <p className="text-lg px-5 font-sans"> (i). Wired Display Unit</p>
          <p className="text-lg px-5 font-sans">(ii). Wireless Display Unit</p>
          <div className="md:flex justify-between gap-10">
            <div className="box-1 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/homeunit")}>
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="mt-3 w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[500px]" src="../image/pro7.JPG" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto md:mt-20">
          <p className="text-3xl font-sans font-bold">
            <u>HOME INDICATOR UNIT</u>
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            AVAILABLE IN WIRELESS UNIT.
          </p>
          <p className="text-lg mt-5 font-sans">
            <i class="far fa-caret-square-right"></i> Indicate customer to
            monitor source of power.
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Eligant design and no
            separate power is required.
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Buzzer Beeps whenever
            Source of Power Changes.
          </p>
          <p className="text-lg font-sans">
            <i class="far fa-caret-square-right"></i> Different beeps to mention
            Grid power or DG power.
          </p>
          <div className="md:flex justify-between gap-10">
            <div className="box-1 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/homeunit")}>
                <span>KNOW MORE</span>
              </div>
            </div>
            <div className="box-11 mt-5 md:mt-10">
              <div class="btn btn-one" onClick={() => navigate("/contactus")}>
                <span>SEND INQUIRY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestCall />
      <Clients />
    </>
  );
};

export default Product;
