import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[130px] md:h-[200px] policy"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mt-5 mx-auto">
            <p className="text-2xl md:text-6xl font-sans text-center text-gray-100 md:text-white font-bold">
              <u>Privacy & Policy(Google 3)</u>
            </p>
          </div>
        </div>
      </div>
      <div className="w-[80%] mt-2 mx-auto md:flex">
        <div className="">
          <p className="text-2xl font-sans italic font-bold about">
            <u>Privacy & Policy</u>
          </p>
          <p className="text-lg font-sans italic font-bold mt-2 text-gray-800">
            PES ELECTRICAL PVTL LTD. is a leading provider of automation and
            networked systems for society and apartments, offering Three phases
            and single-phase energy meter and more into separate or unified
            systems that enhances the daily lives of consumers. These products
            are sold under name of Pes electrical
          </p>
          <p className="text-lg font-sans text-gray-700 font-bold mt-10">
            we understand that you care about your personal information. we
            respect your privacy and are committed to protecting your
            information
          </p>
        </div>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-5">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          COLLECTION OF INFORMATION
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          PES electrical may collect information about you any time you are in
          contact with us, its products, services, or Sites, as further
          described below. we may share this personal information with our
          subsidiaries and data processors (and vice versa) but only for
          purposes consistent with this Privacy Policy.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-5">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          Information You Provide
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          We may ask you to provide personal information when you visit our
          Sites, use our mobile application, register for a program or service,
          subscribe to a newsletter, respond to a survey, fill out a form, or in
          connection with other resources or services we make available to you
          from time to time. We do not intentionally collect any sensitive
          personal information relating to your health or medical history,
          racial or ethnic origins, political or religious beliefs, or
          sexuality; we don’t believe that any of these types of information are
          necessary to any aspect of our relationship.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-5">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          Information Collected Automatically
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          We are strongly committed not to take any personal or non-personal
          information like access to contact, gallery, the location from the
          customer.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-5">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          Information Collected through our Products
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          The collection of some data may be necessary for the functionality of
          our products. When you install our application we may need information
          which may include :{" "}
          <span className="text-lg italic">
            SITE_ID i.e your society id provided by us. customer_id i.e your id
            which is provided by our organization. customer password.
          </span>
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-10">
        <p className="text-xl mt-3 font-sans font-semibold">
          These credentials are necessary to login into our mobile application.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-10">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          HOW THE INFORMATION IS USED
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          To provide you a service you purchase.
        </p>
        <p className="text-xl font-sans font-semibold">
          To provide your electricity usage, an unbilled amount, your
          electricity balance, Electricity readings.
        </p>
        <p className="text-xl font-sans font-semibold">
          To improve our products and services.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-10">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          HOW WE PROTECT YOUR PERSONAL INFORMATION
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          Your personal information and project data files are stored on and the
          dedicated servers of companies we hire to provide us with processing
          and storage services. we continuously evaluate threat and
          vulnerability data to further understand cyber-security risks. Based
          on available information, we may adjust its approach to further defend
          your data. we take precautions including industry-standard
          administrative, technical, and physical measures that are designed to
          safeguard the information collected from visitors and customers
          against loss, theft, misuse, unauthorized access, disclosure,
          alteration, and destruction. When you enter information in our
          application we encrypt this data using SSL or other technologies.
          These technologies are designed to protect the confidentiality of your
          personal information when it is transmitted over the Internet.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-10">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          WHEN WE DISCLOSE YOUR PERSONAL INFORMATION
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          PES ELECTRICAL may also disclose your personal information to a third
          party if required to do so by law or if, in good faith, we believe
          that such action is necessary or in our legitimate interest to:
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          (i). Comply with the law or with legal process or to the extent
          required to protect national security;
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          (ii). Protect and Defend legal rights;
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          (iii). Prevent fraud;
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          (iv). Protect against abuse, misuse, or unauthorized use of our
          products or services;
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          (v). Protect the personal safety or property of personnel, users, the
          public;
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          (vi). Conduct required audits or corporate due diligence.
        </p>
      </div>
      <div className="w-[80%] md:w-[80%] mx-auto mt-10 md:mt-10">
        <p className="text-3xl font-sans font-bold text-gray-600 dgma">
          CHANGES
        </p>
        <p className="text-xl mt-3 font-sans font-semibold">
          Our Privacy Policy may change from time to time, But PES ELECTRICAL
          will not reduce the level of protection of your information collected
          according to a previous version of this Privacy Policy without your
          consent.
        </p>
        <p className="text-xl font-sans font-semibold mt-5 mb-5">
          If you have any questions or concerns about this Privacy Policy or
          your information, please email our Data Protection Officer at
          info@pesonline.co.in
        </p>
      </div>
    </>
  );
};

export default Privacy;
