import React, { useState } from "react";
import "../style.css";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Clients from "./Clients";
import { Link } from "react-router-dom";
import axios from "axios";

const ContactUs = () => {
  const [row, setRow] = useState({
    YourName: "",
    YourEmail: "",
    YourNumber: "",
    Subject: "",
    YourMessage: ""
  });
  const handleChange = (e) => {
    setRow({ ...row, [e.target.name]: e.target.value });
  };
  const handleClick = () => {
    if(row.YourName.trim() === "" || row.YourEmail.trim() === "" || row.YourNumber.trim() === ""){
      alert("Please Fill The Fields")
    }
    axios
    .post(`${process.env.REACT_APP_BASE_URL_2}create`, {
      ...row,
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="flex mt-20 justify-between items-center w-[100%] h-[100px] bg-blue-500">
        <div className="w-[85%] mx-auto">
          <p className="text-4xl font-sans text-white font-bold">Contact Us.</p>
          <Link
            to="/"
            class="mx-auto mt-5 cursor-pointer font-semibold rounded"
          >
            <u>Home</u>
          </Link>
          /Contact Us
        </div>
      </div>
      <div className="flex w-[100%] h-auto bg-gray-400">
        <div className="w-[85%] mx-auto md:flex items-center mt-3 mb-5">
          <div className="md:w-[45%]">
            <p className="text-4xl mb-3 font-sans font-bold about">
              Get in Touch
            </p>
            <img
              src="../image/about.png"
              className="w-[300px] md:w-[500px] h-auto"
              alt=""
            />
          </div>
          <div className="md:grid space-y-3 md:space-y-0 mt-5 grid-cols-2 justify-around items-center gap-5">
            <div>
              <input
                type="text"
                id="first_name"
                name="YourName"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Name"
                required
              />
            </div>
            <div>
              <input
                type="text"
                id="first_name"
                name="YourEmail"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Email"
                required
              />
            </div>
            <div>
              <input
                type="text"
                id="first_name"
                name="YourNumber"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Number"
                required
              />
            </div>
            <div>
              <input
                type="text"
                id="first_name"
                name="Subject"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Subject"
                required
              />
            </div>
            <div>
              <textarea
                type="text"
                rows={4}
                id="first_name"
                name="YourMessage"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Message"
                required
              />
            </div>
            <p></p>
            <div class="self-end">
              <button class="glow-on-hover" type="button" onClick={handleClick}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] border-[1px] border-black mx-auto h-auto bg-gray-400">
        <div className="w-[90%] md:flex justify-between mx-auto">
          <div className="mb-3">
            <p className="text-xl font-sans font-semibold">
              <HomeTwoToneIcon /> Address
            </p>
            <p className="text-sm md:text-md font-serif font-medium text-justify">
              Plot No.1 Opposite Govt School, Sector-73, Faridabad, Haryana -
              121004, India
            </p>
          </div>
          <div className="">
            <p className="text-xl font-sans font-semibold">
              <PhoneInTalkIcon /> Phone
            </p>
            <p className="text-sm md:text-md font-serif font-medium text-justify">
              +91-9810208597, +91-9810261185
            </p>
          </div>
          <div className="">
            <p className="text-xl font-sans font-semibold">
              <AlternateEmailIcon /> Gmail
            </p>
            <p className="text-sm md:text-md font-serif font-medium text-justify">
              info@pesonline.co.in
            </p>
          </div>
          <div className="">
            <p className="text-xl font-sans font-semibold">
              <ReceiptIcon /> Sales
            </p>
            <p className="text-sm md:text-md font-serif font-medium text-justify">
              sales@pesonline.co.in
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3511.0038769936646!2d77.35090287548434!3d28.358732396461566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdbf5f95f7577%3A0x8412584b72c359f!2sPes%20Electrical%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1685530148980!5m2!1sen!2sin"
          title="abc"
          height="450"
          loading="lazy"
          width="100%"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Clients />
    </>
  );
};

export default ContactUs;
