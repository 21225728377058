import axios from "axios";
import React, { useState } from "react";

const RequestCall = () => {
  const [row, setRow] = useState({
    YourName: "",
    YourEmail: "",
    YourNumber: "",
    Subject: "NA",
    YourMessage: "NA"
  });
  const handleChange = (e) => {
    setRow({ ...row, [e.target.name]: e.target.value });
  };
  const handleClick = () => {
    axios
    .post(`${process.env.REACT_APP_BASE_URL_2}create`, {
      ...row,
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="flex w-[100%] h-[350px] md:h-[300px] bg-indigo-500">
        <div className="w-4/5 mx-auto mt-7 md:mt-0 md:flex justify-center items-center">
          <div className="md:w-[45%]">
            <p className="text-3xl md:text-4xl font-sans font-bold text-white">
              Request For Call
            </p>
            <p className="text-md md:text-xl md:mt-3 font-sans font-bold text-white">
              Feel like to get us on Board? Connect with us.
            </p>
          </div>
          <div className="md:grid space-y-3 md:space-y-0 mt-1 grid-cols-2 justify-around items-center gap-5">
            <div>
              <input
                type="text"
                id="first_name"
                name="YourName"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[230px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Name"
                required
              />
            </div>
            <div>
              <input
                type="text"
                id="first_name"
                name="YourEmail"
                onChange={handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[230px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Email"
                required
              />
            </div>
            <div>
              <input
                type="text"
                id="first_name"
                name="YourNumber"
                onChange={handleChange} 
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[230px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Number"
                required
              />
            </div>
            <div className="">
              <button onClick={handleClick} class=" inline-flex items-center justify-center md:mt-2 p-0.5 mb-2 mr-2 text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                <span class=" px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Submit
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestCall;
