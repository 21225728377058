// import logo from "./logo.svg";
// import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import Service from "./components/Service";
import ClientsTwo from "./components/ClientsTwo";
import Product from "./components/Product";
import ThreePhase from "./components/ThreePhase";
import SinglePhase from "./components/SinglePhase";
import DgMain from "./components/DgMain";
import CommHardware from "./components/CommHardware";
import Software from "./components/Software";
import HomeUnit from "./components/HomeUnit";
import ScrollToTop from "./ScrollToTop";
import TermsAndConditions from "./components/TermsandConditions";

import Privacy from "./components/Privacy-apple";
import Privacy2 from "./components/Privacy-google";
import Privacy3 from "./components/Privacy-google-2";
import Privacy4 from "./components/Privacy-google-3";

function App() {
  return (
    <>
      {/* <Home /> */}
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/product" element={<Product />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/clienttwo" element={<ClientsTwo />} />
          <Route path="/threephase" element={<ThreePhase />} />
          <Route path="/singlephase" element={<SinglePhase />} />
          <Route path="/dgmain" element={<DgMain />} />
          <Route path="/communication" element={<CommHardware />} />
          <Route path="/mansoftware" element={<Software />} />
          <Route path="/homeunit" element={<HomeUnit />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/privacypolicy" element={<Privacy2 />} />
          <Route path="/privacyPolicy" element={<Privacy3 />} />
          <Route path="/privacy" element={<Privacy4 />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          {/* <Route path="/www.pesonline.in/pes1" element={<Login />} /> */}
          {/* <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
