import React from "react";
import RequestCall from "./RequestCall";

const ThreePhase = () => {
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[130px] md:h-[200px] threep"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mt-5 mx-auto">
            <p className="text-2xl md:text-6xl font-sans text-center text-gray-100 md:text-white font-bold">
              <u>Three Phase Energy Meter</u>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-3 w-[90%] mx-auto py-2 gap-10 md:flex justify-center items-center">
        <div>
          <img
            className="h-[350px] w-[350px]"
            src="../image/pro1.JPG"
            alt="..."
          />
          <img
            className="h-[350px] w-[350px] mt-5"
            src="../image/threeph2.JPG"
            alt="..."
          />
        </div>
        <div className="mt-5 md:mt-0">
          <img
            className="h-[350px] md:h-[720px] md:w-[800px]"
            src="../image/threeph1.JPG"
            alt="..."
          />
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
     <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="mt-5 w-[100%] md:w-[90%] mb-5 mx-auto py-2 gap-10 md:flex">
        <div className="md:mt-20">
          <img
            className="h-[600px] w-[500px]"
            src="../image/pro1.JPG"
            alt="..."
          />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-2 md:mt-10">
          <p className="text-3xl font-sans font-bold">
            Three Phase Energy Meter
          </p>
          <p className="text-lg mt-5 font-sans">
            <span className="font-semibold"> Measure Standard : </span> IS :
            13779,
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Dimension (L x W x H) : </span> 225
            x 175 x 85 (mm)
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Weight (kg) : </span> 2 Kg
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Starting current : </span> 20mA
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Accuracy class : </span> Active:
            class1.0
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Measure parameter : </span>
            KWH; KW MD, Kvah, Kva
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Other function : </span> Measure AC
            Main Power and DG supply Configure to Prepaid and Post paid
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Meter Type : </span>3 Phase; 4 Wire
            system
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Rating current/voltage : </span>3
            10-60 A, 3x240v/ 415v
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Meter Impulse : </span> 1000
            imp/kWh
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Environment Temperature : </span>{" "}
            Operating temperature range: -100C +600C
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Power consumption : </span> V
            Circuit | 2W/10VA,I circuit | 0.5VA per phase
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Power Factor : </span> 0
            lag-unity-0 lead
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Voltage & Frequency range : </span>{" "}
            3x240v/ 415v (-40% to +20%) at 50 Hz (5%)
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Short over current : </span> 30
            Imax
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold">
              Storage and transport temperature range :{" "}
            </span>{" "}
            -400C +800C
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold">Humidity Relative Humidity :</span>{" "}
            Up to 95% non condensing
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Material :</span> Engineering
            plastic (Polycarbonate)
          </p>
          <p className="text-lg mt-1 font-sans">
            <span className="font-semibold"> Relay Type :</span> Three Phase
            integrated relay for Load control
          </p>
        </div>
      </div>
      <RequestCall />
    </>
  );
};

export default ThreePhase;
