import React from "react";
import "../style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";

const Crousal = () => {
  return (
    <>
      <div className="w-full overflow-y-0">
        <img className="object-cover" src="../image/pesban.jpg" alt="..." />
      </div>
      <div className="w-[80%] mt-2 mx-auto py-2 gap-10 md:flex">
        <div className="">
          <p className="text-xl font-sans italic font-bold text-center about">
            Making available reliable and Customized - Home Display Unit, Three
            Phase Energy Meters, Single Phase Energy Meters, Energy Management
            Communication Hardware, Management Software of various designs,
            sizes and specifications.
          </p>
        </div>
      </div>
      <div className="w-[80%] mt-10 mx-auto py-2 gap-10 md:flex">
        <div className="mx-auto">
          <p className="text-2xl font-sans font-bold">
            <span className="aboutme">
              "Our children will enjoy in their homes electrical energy too
              cheap to meters"
            </span>
            - Lewis L. Strauss
          </p>
        </div>
      </div>
      <div className="w-[80%] mt-10 mx-auto py-2 gap-10 md:flex">
        <div className="text-center mx-auto">
          <p className="text-4xl font-sans font-bold text-center italic">
            Our Milestones
          </p>
          <p className="mt-5 text-sm md:text-lg font-serif font-medium text-center mx-auto">
            We feel proud and at the same time humbled by some of our
            achievements. These inspire us to strive for achieving more.
          </p>
        </div>
      </div>
      <div className="w-[80%] mx-auto h-auto md:flex gap-12">
        <div className="h-auto text-center mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/carone.jpg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            First dual source meter was invented in india by our company
          </p>
        </div>
        <div className="h-auto text-center mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/cp1.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            First online connectivity of Energy meters done in 2008
          </p>
        </div>
        <div className="h-auto text-center mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/cp2.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            First to provide customized and conversional AMF to our customers
          </p>
        </div>
        <div className="h-auto text-center mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/cp3.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            Reduce to wait of AVRs from 40kg to 200g
          </p>
        </div>
      </div>
      <div className="w-[80%] mx-auto h-auto md:flex gap-12">
        <div className="h-auto text-center  mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/cp4.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            First developer of Aviation AVR in India
          </p>
        </div>
        <div className="h-auto text-center  mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/cp5.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            AD30 and AD10 and CIN81 and RN75 at 400hz used in Indian Airforce
            was Indianize by us.
          </p>
        </div>
        <div className="h-auto text-center  mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/cp6.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            3500 meter installed in RPS Faridabad in 2018
          </p>
        </div>
        <div className="h-auto text-center  mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/mile2.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            10,000 meters installed in BPTP homes, flats, Apartments
          </p>
        </div>
      </div>
      <div className="w-[65%] md:w-[40%] h-auto md:flex justify-center items-center mx-auto gap-12">
        <div className="h-auto text-center mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/mile3.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            760 Meters installed at LAGOS Airport Nigeria
          </p>
        </div>
        <div className="h-auto text-center mx-auto w-[250px]">
          <img
            className="h-[250px] md:mt-10"
            src="./image/mile4.jpeg"
            alt="..."
          />
          <p className="mt-2 text-lg md:text-sm font-serif font-semibold">
            BSNL Tower of 18 district of U.P west connected to single point
            server
          </p>
        </div>
      </div>
      <div className="w-full mt-10 mx-auto py-2 gap-10 md:flex productet">
        <div className="w-[80%] h-[120px] md:h-[70px] mx-auto flex justify-baseline items-center">
          <p className="text-3xl font-sans italic font-bold text-gray-100">
            <u>OUR CUSTOMIZED PRODUCTS AND SOLUTIONS</u>
          </p>
        </div>
      </div>
      <div className="w-[100%] md:w-[80%] mt-5 md:mt-0 mx-auto h-auto md:flex gap-12">
        <Link to="/singlephase">
          <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[450px]">
            <img
              className="h-[250px] md:h-[350px] w-[250px] md:w-[350px] mx-auto md:mt-10"
              src="./image/singlephase.png"
              alt="..."
            />
            <p className="mt-2 text-lg md:text-xl font-serif font-semibold">
              SINGLE PHASE METER
            </p>
          </div>
        </Link>
        <Link to="/threephase">
          <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[450px]">
            <img
              className="h-[250px] md:h-[350px] w-[250px] md:w-[350px] mx-auto md:mt-10"
              src="./image/threephase.jpeg"
              alt="..."
            />
            <p className="mt-2 text-lg md:text-xl font-serif font-semibold">
              THREE PHASE METER
            </p>
          </div>
        </Link>
        <Link to="/dgmain">
          <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[450px]">
            <img
              className="h-[250px] md:h-[350px] w-[250px] md:w-[350px] mx-auto md:mt-10"
              src="./image/mile5.jpeg"
              alt="..."
            />
            <p className="mt-2 text-lg md:text-xl font-serif font-semibold">
              HOME AUTOMATION
            </p>
          </div>
        </Link>
      </div>
      <div className="w-[100%] md:w-[80%] mb-5 mx-auto h-auto md:flex gap-12">
        <Link to="/communication">
          <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[450px]">
            <img
              className="h-[250px] md:h-[350px] w-[250px] md:w-[350px] mx-auto md:mt-10"
              src="./image/mile6.jpeg"
              alt="..."
            />
            <p className="mt-2 text-lg md:text-xl font-serif font-semibold">
              COMMUNICATION SYSTEM
            </p>
          </div>
        </Link>
        <Link to="/mansoftware">
          <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[450px]">
            <img
              className="h-[250px] md:h-[350px] w-[250px] md:w-[350px] mx-auto md:mt-10"
              src="./image/mile7.jpeg"
              alt="..."
            />
            <p className="mt-2 text-lg md:text-xl font-serif font-semibold">
              Software Management
            </p>
          </div>
        </Link>
        <Link to="/homeunit">
          <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[450px]">
            <img
              className="h-[250px] md:h-[350px] w-[250px] md:w-[350px] mx-auto md:mt-10"
              src="./image/mile8.JPG"
              alt="..."
            />
            <p className="mt-2 text-lg md:text-xl font-serif font-semibold">
              Display and indicators unit
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};
export default Crousal;
