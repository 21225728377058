import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RequestCall from "./RequestCall";

const CommHardware = () => {
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[130px] md:h-[200px] dtrh"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mt-5 md:mt-10 mx-auto">
            <p className="text-2xl md:text-5xl font-sans text-center text-gray-100 md:text-white font-bold">
              <u>Communication Hardware</u>
            </p>
          </div>
        </div>
      </div>
      <div className="w-[80%] mx-auto ">
        <p className="text-xl mt-5 font-sans font-semibold">
          <u>1. DTRH- Data Transmitting and Receiving Hub</u>
        </p>
        <p className="text-lg mt-5 md:mt-10 font-sans">
          For the past 3 years, we have been serving our respected clients by
          manufacturing and supplying excellent quality DTRH Panel. It is well
          engineered and manufactured using excellent quality raw components by
          advanced technologies to provide with international quality and
          working standards. It is extensively used in many industrial
          applications for variegated purposes. It has LED lights for different
          parameters which proves very useful. DTRH Panel can be availed from us
          in a promised time frame.
        </p>
        <p className="text-xl mt-2 font-sans font-semibold">Features :</p>
        <p className="text-xl font-sans px-5">
          (i) Corrosion resistant outer body
        </p>
        <p className="text-xl font-sans px-5">
          (ii) Easy to operate and maintain
        </p>
        <p className="text-xl font-sans px-5">(iii) Long lasting life</p>
      </div>
      <div className="w-[90%]  mx-auto py-2 gap-10 md:flex">
        <div className="md:mt-10">
          <img className="h-[400px]" src="../image/DTRH1.jpeg" alt="..." />
        </div>
        <div className="mt-5 md:mt-10">
          <img className="h-[400px]" src="../image/DTRH2.JPG" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto md:mt-20">
          <p className="text-2xl mt-4 font-sans font-semibold">
            <u>Features</u>
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Convert signal mode from
            RS485 TO RS232 and vice-versa
          </p>
          <p className="text-lg mt-2 px-3 font-sans">
            <i class="far fa-caret-square-right"></i> Maximum 20 meters can
            connected
          </p>
        </div>
      </div>
      <div className="w-[90%] mx-auto mt-5">
        <p className="text-3xl font-sans font-bold">
          <u>Communication Hardware</u>
        </p>
        <div className="w-[80%] mx-auto mt-5">
          <div>
            <img
              className="h-[200px] md:h-[650px] w-[400px] md:w-[1100px]"
              src="../image/DTRH3.jpeg"
              alt="..."
            />
          </div>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-3 border-gray-400 border"></div>
      <div className="w-[80%] mx-auto ">
        <p className="text-xl mt-5 font-sans font-semibold">
          <u>GPRS MODEM :-</u>
        </p>
        <p className="text-lg mt-3 font-sans">
          We are a Haryana based reputed and well established company engaged in
          manufacturing and supplying excellent quality{" "}
          <span className="font-bold">GPRS Modem.</span> It is manufactured with
          using top notch electronic components with the aid of advanced
          techniques. These modems find wide range of applications in remotely
          data monitor and control, traffic system management, fleet management,
          and power distribution network supervision.We have a dedicated team of
          quality analysts who check{" "}
          <span className="font-bold">GPRS Modem</span> on well defined
          parameters prior to final dispatch.
        </p>
      </div>
      <div className="mt-3 w-[80%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img
            className="h-[300px] md:h-[400px]"
            src="../image/img1.jpg"
            alt="..."
          />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-5 md:mt-10">
          <p className="text-2xl font-sans font-bold">
            <u>Features</u>
          </p>
          <p className="text-lg mt-5 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Compact size.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Embedded web server for
            easy configration.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Modbus/ MQTT protocol.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Configurable RS485
            baudrate, priority and stop bits.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> Completely isolated in
            both RS485 and RJ45.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> One touch recovery.
          </p>
          <p className="text-lg mt-3 font-sans font-semibold">
            <i class="far fa-caret-square-right"></i> LED indications for easy
            setup and trouble shooting.
          </p>
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-5 mb-3">
        <div
          id="default-carousel"
          class="relative w-full"
          data-carousel="slide"
        >
          <div class="relative h-56 overflow-hidden rounded-lg md:h-[700px]">
            <div class="hidden duration-700 ease-in-out" data-carousel-item>
              <img
                src="./image/car1.jpeg"
                class="absolute block w-full object-cover"
                alt="..."
              />
            </div>
            <div class="hidden duration-700 ease-in-out" data-carousel-item>
              <img
                src="./image/car2.jpeg"
                class="absolute block w-full  object-cover"
                alt="..."
              />
            </div>
            <div class="hidden duration-700 ease-in-out" data-carousel-item>
              <img
                src="./image/car3.jpeg"
                class="absolute block w-full  object-cover"
                alt="..."
              />
            </div>
            <div class="hidden duration-700 ease-in-out" data-carousel-item>
              <img
                src="./image/car4.jpeg"
                class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 object-cover"
                alt="..."
              />
            </div>
          </div>
          <div class="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="true"
              aria-label="Slide 1"
              data-carousel-slide-to="0"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 2"
              data-carousel-slide-to="1"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 3"
              data-carousel-slide-to="2"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 4"
              data-carousel-slide-to="3"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 5"
              data-carousel-slide-to="4"
            ></button>
          </div>
          <button
            type="button"
            class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev
          >
            <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
              <span class="sr-only">Previous</span>
            </span>
          </button>
          <button
            type="button"
            class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next
          >
            <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
              <span class="sr-only">Next</span>
            </span>
          </button>
        </div>
      </div>
      <RequestCall />
    </>
  );
};

export default CommHardware;
