import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";

import "../style.css";

const Navbar = () => {
  const [isShow, setIsShow] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const { pathname } = useLocation();

  console.log(pathname.split("/")[1]);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const handelClick = () => {
    setIsShow((prevValue) => !prevValue);
  };

  const handleLinkClick = () => {
    setIsShow(true);
  };

  return (
    <>
      <nav className={`active py-2 md:py-4  }`}>
        <div className="container mx-auto justify-between md:flex md:items-center">
          <div className="flex w-full md:w-auto justify-between items-center">
            <img
              src="../image/bg.png"
              className={`w-[70%]   invert`}
              alt="llll"
            />
            <button
              onClick={handelClick}
              className="border border-solid border-gray-600 px-3 py-1 rounded opacity-50 hover:opacity-75 md:hidden"
              id="navbar-toggle">
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={`${
              isShow ? "hidden" : "flex"
            } bg-[#111827] text-white w-full md:w-fit p-3 md:flex md:bg-transparent flex-col md:flex-row mt-3 md:mt-0`}
            id="navbar-collapse">
            <Link
              to="/"
              onClick={handleLinkClick}
              className="p-2 lg:px-4 md:mx-2 font-semibold cursor-pointer rounded  transition-colors duration-300">
              Home
            </Link>
            <Link
              to="/about"
              onClick={handleLinkClick}
              className="p-2 lg:px-4 md:mx-2 font-semibold cursor-pointer rounded  transition-colors duration-300">
              About Us
            </Link>
            <Link
              to="/product"
              onClick={handleLinkClick}
              className="p-2 lg:px-4 md:mx-2 font-semibold cursor-pointer rounded  transition-colors duration-300">
              Products
            </Link>
            <Link
              to="/service"
              onClick={handleLinkClick}
              className="p-2 lg:px-4 md:mx-2 font-semibold cursor-pointer rounded  transition-colors duration-300">
              AMC Service
            </Link>
            <Link
              to="/contactus"
              onClick={handleLinkClick}
              className="p-2 lg:px-4 md:mx-2 font-semibold cursor-pointer rounded  transition-colors duration-300">
              Contact Us
            </Link>
            <Link
              to="/privacy-policy"
              onClick={handleLinkClick}
              className="p-2 lg:px-4 md:mx-2 font-semibold cursor-pointer rounded  transition-colors duration-300">
              Privacy Policy
            </Link>
            <Link
              // to="/customer/"
              target="_blank"
              to="http://www.pesonline12.in:4100/"
              className="p-2 text-center w-[80px] md:mx-2 bg-green-600 hover:bg-green-500 text-white cursor-pointer rounded-lg duration-300">
              Log In
            </Link>
          </div>
        </div>
        {/* <Navbar /> */}
      </nav>
    </>
  );
};
export default Navbar;
// </^[^.]+$|\.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|ttf|jfif|webp|jpeg|avif|map|json)$)([^.]+)$/i
