import React from "react";
import RequestCall from "./RequestCall";

const Software = () => {
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[130px] md:h-[200px] soft"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mt-5 md:mt-10 mx-auto">
            <p className="text-2xl md:text-5xl font-sans text-center text-gray-100 md:text-gray-200 font-bold">
              <u>System Management Software</u>
            </p>
          </div>
        </div>
      </div>
      <div className="w-[90%] md:w-[70%] mx-auto mt-5 md:flex ">
        <div
          id="default-carousel"
          class="relative w-[90%] md:w-[40%]"
          data-carousel="slide"
        >
          <div class="relative h-[400px] overflow-hidden rounded-lg md:h-[600px]">
            <div
              class="duration-700 ease-in-out object-cover"
              data-carousel-item
            >
              <img
                src="./image/soft1.jpeg"
                class="absolute block w-full h-[400px] md:h-[600px]"
                alt="..."
              />
            </div>
            <div
              class="duration-700 ease-in-out object-cover"
              data-carousel-item
            >
              <img
                src="./image/soft2.jpeg"
                class="absolute block w-full h-[400px] md:h-[600px] "
                alt="..."
              />
            </div>
            <div
              class="duration-700 ease-in-out object-cover"
              data-carousel-item
            >
              <img
                src="./image/soft3.jpeg"
                class="absolute block w-full h-[400px] md:h-[600px]"
                alt="..."
              />
            </div>
          </div>
          <div class="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="true"
              aria-label="Slide 1"
              data-carousel-slide-to="0"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 2"
              data-carousel-slide-to="1"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 3"
              data-carousel-slide-to="2"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 4"
              data-carousel-slide-to="3"
            ></button>
            <button
              type="button"
              class="w-3 h-3 rounded-full"
              aria-current="false"
              aria-label="Slide 5"
              data-carousel-slide-to="4"
            ></button>
          </div>
          <button
            type="button"
            class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev
          >
            <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
              <span class="sr-only">Previous</span>
            </span>
          </button>
          <button
            type="button"
            class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next
          >
            <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
              <span class="sr-only">Next</span>
            </span>
          </button>
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto">
          <p className="text-2xl font-sans font-bold text-cyan-500">
            <u>Customer mobile Application</u>
          </p>
          <p className="text-lg mt-3 px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Online Customer Mobile app
            login.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> User can get balance
            credit through any of the interface.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Remote Display.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Real Time data monitoring.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Recharge slip, recharge
            history for the selected data range.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Monthly billing with
            breakup of EB/DG energy bills and maintenance bill.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Running hours of DG and
            Mains Supply is provided.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Cheque Reconciliation.
          </p>
          <p className="text-2xl mt-5 font-sans font-bold text-cyan-500">
            <u> ALERTS OVER EMAIL/AMS</u>
          </p>
          <p className="text-lg mt-3 px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Sending SMS to the users
            during power outage.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Get alerts for activities
            like Account recharge, Recharge status.
          </p>
          <p className="text-lg px-3 font-sans font-medium">
            <i class="far fa-caret-square-right"></i> Low Credits, Overload,
            Overvoltage, etc.
          </p>
        </div>
      </div>
      <div className="w-[80%] h-auto mx-auto mt-5 border-gray-400 border"></div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img
            className="h-[300px] w-full md:h-[400px] md:mt-3"
            src="../image/software1.jpeg"
            alt="..."
          />
        </div>
        <div className="w-[100%] md:w-[50%] mx-auto mt-5 md:mt-0">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>CUSTOMER WEB PORTAL</u>
          </p>
          <p className="text-lg mt-3 font-sans font-medium">
            Our system is protected by three password
          </p>
          <p className="text-lg mt-2 px-3 font-sans font-normal">(i). User</p>
          <p className="text-lg px-3 font-sans font-normal">(i). Admin</p>
          <p className="text-lg px-3 font-sans font-normal">(i). Super-Admin</p>
          <p className="text-lg mt-3 font-sans">
            <span className="font-semibold">User :</span> User means the person
            from Facility team or other parallel person can see the data for
            more transparency. He will not able to change any configuration or
            data.
          </p>
          <p className="text-lg mt-3 font-sans">
            <span className="font-semibold">Admin :</span> The person who can
            handle the site, can be able to recharge meters, closely watch meter
            status & reading.
          </p>
          <p className="text-lg mt-3 font-sans">
            <span className="font-semibold">Super-Admin :</span> Person can see
            the status and capable to open the configuration & can change load
            limit also. Superadmin can also change flat area & able to give
            credit limit to end user.
          </p>
        </div>
      </div>
      <div className="w-[85%] mx-auto md:flex justify-between mt-6">
        <div className="w-[80%] md:mt-0 md:w-[30%]">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>Basic info</u>
          </p>
          <p className="text-lg mt-2 font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Management Area
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Management Sales
            Department
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> User Group User
            Information
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Meter Data Management
          </p>
        </div>
        <div className="w-[80%] mt-3 md:mt-0 md:w-[30%]">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>Configuration</u>
          </p>
          <p className="text-lg mt-2 font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Tariff Configuration
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Configure Threshold
            limits on Mains and DG separately
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Configure Maintenance
            charges
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Configure Penalty on
            Non - payment
          </p>
        </div>
        <div className="w-[80%] mt-3 md:mt-0 md:w-[30%]">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>Vending Management</u>
          </p>
          <p className="text-lg mt-2 font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Open Account
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Open Account
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> User Top-up
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Batch Top-up
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Re-issue Receipt
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Replace Meter
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> User Overall
            Management
          </p>
        </div>
      </div>
      <div className="w-[85%] mx-auto md:flex justify-between mt-6 mb-5 ">
        <div className="w-[80%] md:mt-0 md:w-[30%]">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>Daily Report</u>
          </p>
          <p className="text-lg mt-2 font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Purchasing/ Selling &
            replacement detail
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Consumption history of
            Individual
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Billing History
          </p>
        </div>
        <div className="w-[80%] mt-3 md:mt-0 md:w-[30%]">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>System Configuration</u>
          </p>
          <p className="text-lg mt-2 font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Authorization
            Configuration
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Operation Diary Modify
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Log-in Password
          </p>
        </div>
        <div className="w-[80%] mt-3 md:mt-0 md:w-[30%]">
          <p className="text-[21px] font-sans font-bold text-blue-600">
            <u>Audit Function</u>
          </p>
          <p className="text-lg mt-2 font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> To monitor overall
            consumption consumers can be grouped
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Common area can be put
            into seperate group
          </p>
          <p className="text-lg font-sans font-normal">
            <i class="far fa-arrow-alt-circle-right"></i> Auto audit functions
            to show losses
          </p>
        </div>
      </div>
      <RequestCall />
    </>
  );
};

export default Software;
