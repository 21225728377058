import React from "react";
import RequestCall from "./RequestCall";

const HomeUnit = () => {
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[130px] md:h-[200px] homeu"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mt-5 md:mt-10 mx-auto">
            <p className="text-2xl md:text-5xl font-sans text-center text-gray-100 md:text-gray-200 font-bold">
              <u> Home Display Indicator Unit</u>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 w-[80%] mx-auto md:flex mb-5">
        <div className="w-full">
          <div>
            <img
              className="h-[200px] md:h-[400px] w-[225px] md:w-[450px]"
              src="../image/home1.JPG"
              alt="..."
            />
            <img
              className="h-[200px] md:h-[400px] w-[225px] md:w-[450px] mt-3"
              src="../image/mile8.JPG"
              alt="..."
            />
          </div>
        </div>
        <div className="w-full mt-5">
          <div className="mx-auto mt-5 md:mt-0">
            <p className="text-[21px] font-sans font-bold">
              <u>HOME DISPLAY</u>
            </p>
            <p className="text-lg mt-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Allowing customer to
              monitor consumption.
            </p>
            <p className="text-lg mt-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Eligant design and
              no separate power is required.
            </p>
            <p className="text-lg mt-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Display all
              parameters including balance.
            </p>
            <p className="text-lg mt-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Display blinks and
              buzzer beeps in frequent interval to indicate low balance or at
              the time of power source change.
            </p>
            <p className="text-[19px] mt-20 font-sans font-semibold text-blue-500">
              <u>Communication with meter Power source</u>
            </p>
            <p className="text-lg mt-2 px-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> If connected by
              RS-485, source power from meter
            </p>
            <p className="text-[22px] mt-20 font-sans font-semibold text-blue-500">
              <u>Display meter parameter</u>
            </p>
            <p className="text-lg mt-2 px-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Instant Voltage
            </p>
            <p className="text-lg mt-2 px-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Instant Current
            </p>
            <p className="text-lg mt-2 px-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Instant Power
              History energy and cost
            </p>
            <p className="text-lg mt-2 px-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Meter Date and Time
            </p>
            <p className="text-lg mt-2 px-3 font-sans">
              <i class="far fa-arrow-alt-circle-right"></i> Types of Home
              Display:
            </p>
            <p className="text-lg mt-2 px-7 font-sans font-semibold">
              (i) Wired Display Unit
            </p>
            <p className="text-lg mt-2 px-7 font-sans font-semibold">
              (ii) Wireless Display Unit
            </p>
          </div>
        </div>
      </div>
      <RequestCall />
    </>
  );
};

export default HomeUnit;
