import React from "react";
import "../style.css";
import { Link } from "react-router-dom";
import RequestCall from "./RequestCall";
import Clients from "./Clients";

const Service = () => {
  return (
    <>
      {/* <div className="main"> */}
      <div className="flex mt-20 justify-between items-center w-[100%] h-[150px] md:h-[150px] bg-gray-400">
        <div className="w-[85%] mx-auto">
          <p className="text-2xl md:text-3xl font-sans text-gray-800 font-bold">
            Undertake AMC Service of Prepaid Metering System of Residential
            Housing Society
          </p>
          <Link
            to="/"
            class="mx-auto mt-5 cursor-pointer font-semibold rounded"
          >
            <u>Home</u>
          </Link>
          /Services
        </div>
      </div>
      <div className="w-[80%] mt-2 mx-auto py-2 gap-10 md:flex">
        <div className="">
          <p className="text-4xl font-sans font-bold text-center  about">
            Why Choose Us.
          </p>
        </div>
      </div>
      <div className="w-[80%] mx-auto py-2 gap-10">
        <div className="">
          <img
            src="https://www.labotek.in/assets/uploads/solution-banner-amc.png"
            className=""
            alt="..."
          />
        </div>
        <div className="mt-5">
          <p className="text-lg font-sans italic font-bold text-cente about">
            To gain a massive appreciation of our valued clients, we are held in
            rendering "Energy meter AMC service" at the client's location.
          </p>
          <div className="mt-5">
            <p className="text-lg font-sans italic font-bold text-cente about">
              These comprise annual repairs and checking of all DG, small
              generators, motors, etc.
            </p>
          </div>
          <div className="mt-5">
            <p className="text-lg font-sans italic font-bold text-cente about">
              The existing service is performed by our trained professionals as
              per the client’s demand. We are supported by an advanced tool room
              facility that helps us in finishing these services in the best
              possible time. To accomplish the precise requirements of our
              patrons, we also deliver these services in various modified
              options. Apart from this, we offer these services at economical
              rates. Our knowledge, around 05 years of experience and unmatched
              service will ensure the best platform to achieve energy cost
              reduction targets and productivity improvement.
            </p>
          </div>
        </div>
      </div>
      <div className="w-[80%] mx-auto py-2 gap-10">
        <div className="mt-5">
          <p className="text-lg font-sans font-bold">
            1. Undertake for : Amc for Housing Energy Meter of Any Brand
          </p>
          <div className="mt-5">
            <p className="text-lg font-sans font-bold">
              2. Includes : Android mobile application to show live balance,
              load, kwh, etc.
            </p>
          </div>
          <div className="mt-5">
            <p className="text-lg font-sans font-bold">
              3. Support : 24*7 Customer Service Support
            </p>
          </div>
        </div>
      </div>
      {/* <div className="w-[80%] mx-auto py-2 aboutserv">
        <div className="mt-5">
          <p className="text-xl font-sans italic font-bold text-cente about">
            To gain a massive appreciation of our valued clients, we are held in
            rendering "Energy meter AMC service" at the client's location.
          </p>
        </div>
      </div> */}
      <div className="amcservice mt-5 h-[600px] md:h-[200px] mx-auto mb-5 flex justify-center items-center">
        <div className="mt-5 w-[70%]">
          <p className="text-3xl font-sans italic font-bold text-center about">
            10+ Years of AMC Service For Energy Meters We are the best to
            provide customized service, maintenance, and repairs of all makes
            and models of ENERGY METERS at a fair price.
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center w-full h-[100px] clients">
        <p className="flex justify-center items-center text-2xl md:text-3xl h-[50px] font-sans text-center font-bold text-center about">
          Key Features
        </p>
      </div>
      <div className="amcservicemain mt-5 w-[90%] md:w-[40%] h-[1550px] md:h-[950px] mx-auto mb-5 flex justify-center items-center">
        <div className="w-[70%] mb-10">
          <p className="text-3xl font-sans italic font-bold text-center text-gray-800">
            All our Energy Meters are compliant with IS:13779 specifications
            from BIS. This ensures that all Electricity Meters meet the required
            quality and precision standards. All PES ELECTRICAL devices
            installed as a part of the solution are made from industrial-grade
            specifications components, thereby ensuring an installation that
            works reliably under all weather conditions for several years.
          </p>
        </div>
      </div>
      <div className="w-[100%] mx-auto h-auto flex justify-center items-center mb-2 bg-slate-400">
        <div className="w-[50%] md:flex justify-center items-center py-5 mx-auto h-auto mb-2 gap-4">
          <div className="">
            <img
              className="md:h-[460px] w-[400px] rounded-lg md:mt-0"
              src="./image/dash1.png"
              alt="..."
            />
          </div>
          <div className="md:w-[300px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[200px] md:mt-5"
                src="./image/dash2.png"
                alt="..."
              />
            </div>
            <div className="bg-white w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[200px] md:mt-5"
                src="./image/dash3.png"
                alt="..."
              />
            </div>
          </div>
          <div className="md:w-[300px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[200px] md:mt-5"
                src="./image/dash4.png"
                alt="..."
              />
            </div>
            <div className="bg-white w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[200px] md:mt-5"
                src="./image/dash5.png"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] md:w-[60%] mx-auto md:mb-3 benefits h-[900px] md:h-[650px]">
        <div className="mx-auto text-center">
          <p className="flex justify-center items-center text-2xl md:text-3xl h-[50px] font-sans font-bold">
            <u>Benefits</u>
          </p>
          <div className="flex flex-col w-[90%] md:w-[50%] py-5 gap-8 mb-10 mx-auto">
            <p className="text-xl font-sans italic font-bold text-gray-900">
              1. User-friendly ios and android mobile apps.
            </p>
            <p className="text-xl font-sans italic font-bold text-gray-900">
              2. Real-time energy consumption (kWh) and balance (Rs.) amount
            </p>
            <p className="text-xl font-sans italic font-bold text-gray-900">
              3. Safe and secure online recharge.
            </p>
            <p className="text-xl font-sans italic font-bold text-gray-900">
              4. Real-time alerts for energy source switch.
            </p>
            <p className="text-xl font-sans italic font-bold text-gray-900">
              5. Personalized demand management to optimize consumption.
            </p>
            <p className="text-xl font-sans italic font-bold text-gray-900">
              6. Transparency in tariff slabs, consumption & billing.
            </p>
            <p className="text-xl font-sans italic font-bold text-gray-900">
              7 . Third party Integration such as Paytm, Adda, Mygate, ISociety,
              SocietyManager, NoBrokerHood.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full h-[100px] manage">
        <p className="flex justify-center items-center text-2xl md:text-3xl h-[50px] font-sans text-center font-bold text-center about">
          THE WAY YOUR SOCIETY WILL MANAGED by PES ELECTRICAL Pvt.Ltd
        </p>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img
            className="h-[400px] w-[520px]"
            src="../image/amc1.png"
            alt="..."
          />
        </div>
        <div>
          <img
            className="h-[400px] w-[520px]"
            src="../image/amc2.png"
            alt="..."
          />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc3.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc4.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc5.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc6.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc7.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc8.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc9.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc10.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc11.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc12.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc13.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc14.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc15.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc16.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc17.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc18.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc19.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc20.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc21.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc22.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc23.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc24.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc25.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc26.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc27.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc28.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc29.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc30.png" alt="..." />
        </div>
      </div>
      <div className="mt-3 w-[85%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[400px]" src="../image/amc31.png" alt="..." />
        </div>
        <div>
          <img className="h-[400px]" src="../image/amc32.png" alt="..." />
        </div>
      </div>
      <RequestCall />
      <Clients />
    </>
  );
};

export default Service;
