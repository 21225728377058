import React from "react";
import RequestCall from "./RequestCall";

const DgMain = () => {
  return (
    <>
      <div className="relative mt-20 ">
        <div className="block w-[100%] h-[130px] md:h-[200px] dgmain"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mt-5 mx-auto">
            <p className="text-2xl md:text-5xl font-sans text-center text-gray-100 md:text-white font-bold">
              <u>WIFI SMART DG-MAINS PLUG</u>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 w-[90%]  mx-auto py-2 gap-1 md:flex">
        <div>
          <img
            className="h-[200px] md:h-[400px] w-[500px]"
            src="../image/dg1.jpeg"
            alt="..."
          />
        </div>
        <div className="w-[100%] mt-5 md:mt-0 md:w-[40%] mx-auto flex items-center">
          <p className="text-3xl font-sans font-bold aboutmeee">
            FACING <span className="text-red-700">HIGH</span> ELECTRICITY BILLS
            ?
          </p>
        </div>
      </div>
      <div className="mt-5 w-[90%]  mx-auto py-2 gap-1 md:flex">
        <div className="w-[100%] mt-5 md:mt-0 md:w-[40%] mx-auto flex items-center">
          <p className="text-4xl font-sans font-bold aboutmeee">
            Facing Frequent <span className="text-red-700">power trip </span> at
            night ?
          </p>
        </div>
        <div>
          <img
            className="h-[200px] md:h-[400px] w-[500px] mt-5 md:mt-0"
            src="../image/dg2.jpeg"
            alt="..."
          />
        </div>
      </div>
      <div className="mt-5 w-[90%]  mx-auto py-2 gap-1 md:flex">
        <div>
          <img
            className="h-[200px] md:h-[400px] w-[500px]"
            src="../image/dg3.jpeg"
            alt="..."
          />
        </div>
        <div className="w-[100%] mt-3 md:mt-0 md:w-[45%] mx-auto flex items-center">
          <p className="text-3xl font-sans leading-loose font-bold aboutmeee">
            FOR ALL YOUR PROBLEMS THERE IS ONE STOP SOLUTION.... Smart PLUG{" "}
            <span className="text-cyan-500">
              o kardy apki life auro se ALAG
            </span>
            ?
          </p>
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-5">
        <div>
          <img
            className="h-[200px] md:h-[650px] w-[400px] md:w-[1100px]"
            src="../image/dg4.jpeg"
            alt="..."
          />
        </div>
      </div>
      <div className="relative">
        <div className="block w-[100%] h-[900px] md:h-[550px] dgwifi"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="mt-5 w-[80%]  mx-auto py-2 gap-10 md:flex">
            <div>
              <img
                className="h-[300px] md:h-[450px] w-[450px]"
                src="../image/dg5.jpg"
                alt="..."
              />
            </div>
            <div className="w-[100%] md:w-[50%] mx-auto mt-2 md:mt-10">
              <p className="text-3xl font-sans italic font-bold">
                <u>Salient Features</u>
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                1. Switch on/off your appliances from anywhere.
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                2. Switch off your load when power source feed is DG.
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                3. Schedule your Plug on/off anytime as per your choice.
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                4. Easy to configure.
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                5. Easy to install.
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                6. Fast and reliable customer support.
              </p>
              <p className="text-lg mt-5 font-sans font-semibold">
                7. One year warranty.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] h-[250px] md:h-[450px] mx-auto flex justify-center items-center bg-white">
        <div>
          <img
            className="h-[200px] md:h-[400px]"
            src="../image/dg6.jpeg"
            alt="..."
          />
        </div>
      </div>
      <div className="relative">
        <div className="block w-[100%] h-[220px] md:h-[150px] dgwifione"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mx-auto mt-2">
            <p className="text-3xl font-sans font-bold text-sky-700 dgma">
              <u>MANUAL MODE</u>
            </p>
            <p className="text-xl mt-5 font-sans font-semibold">
              • Default mode, It uses your WI-FI network to communicate between
              your smartphone and the smart switch
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="block w-[100%] h-[300px] md:h-[160px] dgwifitwo"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mx-auto mt-3">
            <p className="text-3xl font-sans font-bold text-sky-700 dgma">
              <u>SCHEDULER MODE</u>
            </p>
            <p className="text-xl mt-5 font-sans font-semibold">
              • With the PES SMART APP manual and Timer Mode, you can automate
              your lights or plugged-in appliances to turn on and off according
              to specific schedules, to simulate occupancy when you are away
              from home.
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="block w-[100%] h-[360px] md:h-[200px] dgwifithree"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mx-auto mt-3">
            <p className="text-3xl font-sans font-bold text-sky-700 dgma">
              <u>AUTO MODE</u>
            </p>
            <p className="text-xl mt-5 font-sans font-semibold">
              • With the PES SMART APP, you can set a device on AUTOMODE where
              Switch will <span className="text-red-700	">TURNOFF</span> the
              appliance (upto 16 AMP-Ac, geyser, etc.) when the source of power
              is from <span className="text-red-700	">Society Generator</span>{" "}
              and also automatically{" "}
              <span className="text-green-600">TURNON</span> the device when the
              source of power coming from the{" "}
              <span className="text-green-600">MAINS</span>.
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="block w-[100%] h-[80px] md:h-[80px] gdmain"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 ">
          <div className="w-[100%] md:w-[80%] mx-auto flex items-center">
            <p className="text-2xl md:text-3xl h-[50px] font-sans italic text-center font-bold text-center text-white">
              <u>Other features</u>
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="block w-[100%] h-[250px] md:h-[540px] tarun"></div>
        <div className="space-y-5 absolute w-[100%] top-5 px-4 flex items-center justify-center">
          <div>
            <img
              className="h-[200px] md:h-[500px]"
              src="../image/features.jpeg"
              alt="..."
            />
          </div>
        </div>
      </div>
      <RequestCall />
    </>
  );
};

export default DgMain;
