// import logo from "./logo.svg";
import React from "react";
import Crousal from "./Crousal";
import "../style.css";
// import "./App.css";
// import MobileApp from "./MobileApp";
import RequestCall from "./RequestCall";
import Clients from "./Clients";
// import Modal from "./Modal";

function Home() {
  return (
    <>
      <Crousal />
      {/* <MobileApp /> */}
      <div className="flex justify-center items-center bg-blend-color bg-gray-600 w-full h-[100px] work">
        <p className="flex justify-center items-center text-white text-2xl md:text-4xl font-sans text-center font-bold ">
          <u>Industries we Work In</u>
        </p>
      </div>
      <div className="w-[100%] md:w-[80%] mb-5 mx-auto h-auto md:flex gap-12">
        <div className="h-auto text-center  mx-auto w-[350px] md-w-[700px]">
          <img
            className="h-[300px] md:h-[400px] md:mt-10 w-[700px]"
            src="./image/mile9.png"
            alt="..."
          />
        </div>
        <div className="h-auto text-center mt-5 mx-auto w-[350px] md-w-[700px]">
          <img
            className="h-[300px] md:h-[400px] md:mt-5"
            src="./image/mile10.jpeg"
            alt="..."
          />
        </div>
      </div>
      <RequestCall />
      <div className="w-full">
        <Clients />
      </div>
    </>
  );
}

export default Home;
