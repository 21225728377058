import React from "react";
import "../style.css";
import Clients from "./Clients";

const AboutUs = () => {
  return (
    <>
      <div className="w-[80%] mt-20 mx-auto py-2 gap-10 md:flex">
        <div className="">
          <p className="text-4xl font-sans font-bold text-center  about">
            Our Legacy
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            To contribute towards the economic growth as well as environment
            protection by replacing power consuming products with energy
            management products . Our firm is a specialized manufacturer,
            supplier of Three and Single Phase Perpaid & Postpaid Energy Meters,
            Energy Management Communication Hardware, Management Software, etc.
            Our products are simple to install/ operate, modern in design,
            reliable, and durable due to which these are extensively demanding.
            For meeting the different requirements of the clients, we are making
            available our products in several models, sizes, and technical
            specifications. In addition to the impressive gamut, we are growing
            in the industry leveraging the hard work of our professionals and
            support of the linked eminent firms.
          </p>
        </div>
        <div>
          <div className="w-[300px] md:w-[600px] h-auto mt-2 md:mt-16">
            <div>
              <img
                className="w-[300px] md:w-[500px]"
                src="https://catalog.wlimg.com/1/9046545/small-images/about-us-2966228.jpg"
                alt="..."
              />
              {/* <img src="../image/about.png" alt="..." /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-[80%] mx-auto">
        <p className="text-4xl font-sans font-bold text-center  about">
          Our Team
        </p>
        <p className="mt-3 text-sm md:text-lg font-serif text-center font-medium text-justify">
          Our people are our greatest asset and differentiator. They are
          passionate about results, innovations and also believe in having a lot
          of fun.
        </p>
      </div> */}
      <div className="mt-3 w-[80%]  mx-auto py-2 gap-10 md:flex">
        <div>
          <img className="h-[350px]" src="../image/ceo.png" alt="..." />
        </div>
        <div className="w-[100%] md:w-[50%]">
          <p className="text-4xl font-sans font-bold about">C P Yadav</p>
          <p className="mt-3 text-lg md:text-2xl font-serif font-bold text-justify">
            Founder, Promoter & MANAGING DIRECTOR
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            C P Yadav is a serial entrepreneur and an industry thought leader.
            He has been instrumental in innovating and commercializing several
            products and solutions in the areas of automation, infrastructure,
            energy and fibre communication, his latest interest being in the
            IoT/M2M domain.
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            He holds several global patents for neutral access fibre to the home
            in his name. His current interests involve leveraging Iot in the
            diverse areas like energy, connected living, smart cities and
            related solution.
          </p>
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-5 mb-5">
        <div className="">
          <p className="text-2xl font-sans font-bold about">
            <u>Quality Assurance</u>
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            To establish ourselves in the roots of the industry, we are giving
            high attention on making and delivering quality products to the
            clients. Under the observation of experienced quality controllers,
            our entire range is made from the finest materials in sync with the
            national norms. These personnel also evaluate all the developed as
            well as procured items of our energy management and home display
            units to ensure absolute perfection, before allowing for dispatch.
            In the testing cell, our gamut is checked on the grounds of its
            finishing, performance, durability, construction, etc.
          </p>
        </div>
        <div className="mt-3">
          <p className="text-2xl font-sans font-bold about">
            <u>Infrastructure We Own</u>
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            Technologically updated infrastructure is constructed by us over a
            wide area of land to have hassle-free execution of the trade tasks.
            It includes specific cells of quality checking, storage, production,
            accounting, research & development, production, etc. The
            manufacturing cell supports us like a backbone, here we have
            installed several machines, tools and devices that aid in making the
            gamut in an excellent speed. After production, the entire range is
            stocked in bulk quantity at the maintained massively spreading
            spacious warehouse.
          </p>
        </div>
        <div className="mt-3">
          <p className="text-2xl font-sans font-bold about">
            <u>Our Vision & Mission</u>
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            PES ELECTRICAL PVT LTD shall be the most trusted, respected and
            preferred brand for Three Phase Energy Meters, Single Phase Energy
            Meters Home Display Unit, AMF Relays, Energy Management
            Communication Hardware, Management Software, Smart dg-main Plug, and
            AVRs.
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            As a leading company, PES ELECTRICAL plays a critical role in
            tackling the energy transition and the impact of widespread
            digitalization on people’s lives:
          </p>
          <p className="mt-3 text-sm md:text-lg font-serif font-medium text-justify">
            Our Mission is to provide innovative energy solutions and products
            on the go every day everywhere.
          </p>
        </div>
      </div>
      <Clients />
    </>
  );
};

export default AboutUs;
