import React from "react";
import { Link } from "react-router-dom";
import RequestCall from "./RequestCall";

const ClientsTwo = () => {
  return (
    <>
      <div className="flex mt-20 justify-between items-center w-[100%] h-[200px] md:h-[130px] clientmain bg-violet-200	">
        <div className="w-[85%] mx-auto">
          <p className="text-4xl font-sans text-white font-bold">
            Our Clients and Partners
          </p>
          <Link
            to="/"
            class="mx-auto mt-5 text-xl text-white cursor-pointer font-bold rounded"
          >
            <u>Home</u>/
            <span className="text-white font-normal text-base cursor-auto">
              Clients
            </span>
          </Link>
        </div>
      </div>
      {/* //////////////// */}
      <div className="w-[80%] border-black mt-7 border-[1px] py-5 mx-auto h-auto mb-5">
        <div className="w-[80%] md:flex justify-center items-center py-2 mx-auto h-auto mb-2 gap-4">
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://img.squadhelp.com/story_images/visual_images/1653413393-grandura.png?class=show"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://upload.wikimedia.org/wikipedia/commons/2/27/National_Thermal_Power_logo.svg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://img.etimg.com/thumb/msid-47148974,width-300,height-225,imgsize-54360,,resizemode-75/.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/ats.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/amarpali.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/bptp.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/logic.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/rps.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/wavecity.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="./image/aster.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://images.jdmagicbox.com/comp/noida/u1/011pxx11.xx11.170904142618.f6u1/catalogue/imperia-mirage-noida-15yqms18sp.jpg?temp=1"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://i.pinimg.com/564x/1e/d0/b7/1ed0b7df52db6ab3762db45c831eab23.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/inter.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://2.imimg.com/data2/RQ/XR/MY-3503293/orris-carnation-sector-85-gurgaon-250x250.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://as1.ftcdn.net/v2/jpg/05/18/90/20/1000_F_518902023_MmFLubQCKI4xWE1ruWxSUnWVBr3x15Dg.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.logodesignlove.com/images/classic/royal-parks-logo.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
        </div>
        <div className="w-[80%] md:flex justify-center items-center py-2 mx-auto h-auto mb-2 gap-4">
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-gray-500 relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="http://www.logixgroup.in/images/logo.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px] w-full"
                src="./image/orissco.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.eldecogroup.com/assests/uploads/project/logo/1582180264309634278.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/b920b664fc53eac73532"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://upload.wikimedia.org/wikipedia/commons/a/ac/Indiabulls-logo_campaign_new.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://is1-3.housingcdn.com/0b8ad14c/bcbdae682e5a89f9bc46d98e40616ca1/v1/thumb.jpg.webp"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://saffronvalleyfarmhouses.com/wp-content/uploads/2022/11/cropped-SAFFRON-VALLEY.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.thetphgroup.com/corporate/assets/imgs/TPH_Group_Logo.svg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://newprojects.99acres.com/projects/3c/Lotus_Zing/loguijoi.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.mahagunindia.com/wp-content/uploads/2023/04/logo.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.apartmentpages.co.ke/uploads/apartment/20180710_134501.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://s3-media0.fl.yelpcdn.com/bphoto/jEJ61Dw_Joj8mL_CNp4nZQ/o.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.assotech.in/assets/img/logo1.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px] w-full"
                src="https://is1-3.housingcdn.com/0b8ad14c/da55ca99b0bf0fb307a4d5f315f8a309/v0/medium.jpeg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://prodcrm.s3.amazonaws.com/company_logo/100818031220Capture.JPG"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://newprojects.99acres.com/projects/aadideva_land_promoters/bbdrzaht.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
        </div>
        <div className="w-[80%] md:flex justify-center items-center py-2 mx-auto h-auto mb-2 gap-4">
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-gray-500 relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://2.imimg.com/data2/VS/JL/MY-3207688/park-floors-i-250x250-250x250.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px] w-full"
                src="./image/eledico.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="./image/bks.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="./image/prip.png"
                 alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://jcboseust.ac.in/assets/uploads/media/e11f95725a67cdcc447f76a7fa75eef7.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://iitlgroup.com/App_Themes/Common/Images/logo.gif"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.iitlprojects.com/images/LogoBk.gif"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://designcontest.nyc3.digitaloceanspaces.com/data/contests/6939/entries/Oc3fodhse.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img className="h-[100px]" src="./image/akash.png" alt="..." />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.addressofchoice.com/aoc_assets/developer/Divyansh_Promoters_And_Developers_195_.jpeg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://lh3.googleusercontent.com/p/AF1QipPAJOht4eThAGw5Dbez7IubvtjQ55p-vIsfyzDi=w1080-h608-p-no-v0"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.karnielectrical.com/wp-content/uploads/2021/01/logo.jpg"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://zodiachousing.com/wp-content/uploads/2021/08/ZodiacHousingLogo-1.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px] w-full"
                src="https://elitefloor.com/wp-content/uploads/2021/01/elite-logo.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
          <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
            <div className="bg-white relative cursor-pointer w-full items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://lh3.googleusercontent.com/p/AF1QipN-sJAKki35i8GhIXC8pmNXUj2LcLKURK4VKB4z=w960-h960-n-o-v1"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
            <div className="bg-white w-full relative cursor-pointer items-center flex flex-col rounded-lg">
              <img
                className="h-[100px]"
                src="https://www.nicepng.com/png/full/271-2712471_ymca-logo-nz.png"
                alt="..."
              />
              <div className="absolute hover:bg-gray-300 opacity-40 inset-0"></div>
            </div>
          </div>
        </div>
      </div>
      <RequestCall />
    </>
  );
};

export default ClientsTwo;
