// import React, { useEffect, useRef } from "react";
import React from "react";
import "../style.css";
// import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Clients() {
  const navigate = useNavigate();
  //   const logosRef = useRef(null);
  //   useEffect(() => {
  //     const logosSlide = logosRef.current?.querySelector(".slide-image");
  //     if (logosSlide) {
  //       const copy = logosSlide.cloneNode(true);
  //       logosRef.current.appendChild(copy);
  //     }
  //   }, []);
  return (
    <>
      <div className="flex justify-center items-center w-full h-[100px] clients">
        <p className="flex justify-center items-center text-2xl md:text-3xl h-[50px] font-sans text-center font-bold text-center about">
          Our Clients
        </p>
      </div>
      <div className="w-[80%] md:flex justify-center items-center py-5 mx-auto h-auto mb-2 gap-4">
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://img.squadhelp.com/story_images/visual_images/1653413393-grandura.png?class=show"
              alt="..."
            />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://upload.wikimedia.org/wikipedia/commons/2/27/National_Thermal_Power_logo.svg"
              alt="..."
            />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://img.etimg.com/thumb/msid-47148974,width-300,height-225,imgsize-54360,,resizemode-75/.jpg"
              alt="..."
            />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px] md:mt-" src="./image/ats.png" alt="..." />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/amarpali.png" alt="..." />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/bptp.png" alt="..." />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/logic.png" alt="..." />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/rps.png" alt="..." />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/wavecity.png" alt="..." />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/atpl.jfif" alt="..." />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://parasgroup.net/wp-content/uploads/2019/10/WhatsApp-Image-2019-10-18-at-14.46.252.jpeg"
              alt="..."
            />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/lotus.jfif" alt="..." />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://images.jdmagicbox.com/comp/noida/u1/011pxx11.xx11.170904142618.f6u1/catalogue/imperia-mirage-noida-15yqms18sp.jpg?temp=1"
              alt="..."
            />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://i.pinimg.com/564x/1e/d0/b7/1ed0b7df52db6ab3762db45c831eab23.jpg"
              alt="..."
            />
          </div>
        </div>
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img className="h-[100px]" src="./image/inter.png" alt="..." />
          </div>
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://2.imimg.com/data2/RQ/XR/MY-3503293/orris-carnation-sector-85-gurgaon-250x250.jpg"
              alt="..."
            />
          </div>
        </div>
        {/* <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-white w-full items-center flex flex-col rounded-lg">
            <img
              className="h-[100px]"
              src="https://www.logodesignlove.com/images/classic/royal-parks-logo.jpg"
              alt="..."
            />
          </div>
        </div> */}
        <div className="md:w-[120px] mt-5 md:mt-0 space-y-5">
          <div className="bg-whie w-full items-center flex flex-col rounded-lg">
            <button
              onClick={() => navigate("/clienttwo")}
              class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
