import React from "react";
import { Link } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";

const Footer = () => {
  return (
    <>
      {/* <footer class="bg-indigo-100 text-center text-white dark:bg-neutral-600 dark:text-neutral-200">
        <div class="container p-6 mx-auto mb-0">
          {window.innerWidth > 500 ? (
            <div class="grid gap-3 items-center justify-center md:grid-cols-3 lg:grid-cols-6">
              <div class="mb-6 lg:mb-0">
                <img
                  src="https://peselectricals.com/img/8475mile4.jpg"
                  alt="..."
                  // src="https://tecdn.b-cdn.net/img/new/fluid/city/113.webp"
                  class="w-full h-[250px] rounded-md shadow-lg"
                />
              </div>
              <div class="mb-6 lg:mb-0">
                <img
                  src="https://peselectricals.com/img/7855m5.jpg"
                  alt="..."
                  // src="https://tecdn.b-cdn.net/img/new/fluid/city/111.webp"
                  class="w-full h-[250px] rounded-md shadow-lg"
                />
              </div>
              <div class="mb-6 lg:mb-0">
                <img
                  src="../image/app6.png"
                  alt="..."
                  // src="https://tecdn.b-cdn.net/img/new/fluid/city/112.webp"
                  class="w-full h-[250px] rounded-md shadow-lg"
                />
              </div>
              <div class="mb-6 lg:mb-0">
                <img
                  src="https://peselectricals.com/img/1205m8.jpg"
                  alt="..."
                  // src="https://tecdn.b-cdn.net/img/new/fluid/city/114.webp"
                  class="w-full h-[250px] rounded-md shadow-lg"
                />
              </div>
              <div class="mb-6 lg:mb-0">
                <img
                  src="https://peselectricals.com/img/5648mile1.jpg"
                  alt="..."
                  // src="https://tecdn.b-cdn.net/img/new/fluid/city/115.webp"
                  class="w-full h-[250px] rounded-md shadow-lg"
                />
              </div>
              <div class="mb-6 lg:mb-0">
                <img
                  src="https://peselectricals.com/img/1557mile3.jpg"
                  alt="..."
                  // src="https://tecdn.b-cdn.net/img/new/fluid/city/116.webp"
                  class="w-full h-[250px] rounded-md shadow-lg"
                />
              </div>
            </div>
          ) : (
            <div className="mx-auto mt-3">
              <Carousel
                className="w-[300px] h-auto mx-auto mb-6"
                swipeable={true}
                infiniteLoop={true}
                showArrows={false}
                showIndicators={false}
                interval={7000}
                stopOnHover={false}
                dynamicHeight={true}
                autoPlay={true}
                showThumbs={false}
                // width={500}
                showStatus={false}
              >
                <div>
                  <img
                    src="https://peselectricals.com/img/8475mile4.jpg"
                    alt="..."
                    className="w-full h-[360px] rounded-md shadow-lg"
                  />
                </div>
                <div>
                  <img
                    src="https://peselectricals.com/img/7855m5.jpg"
                    alt="..."
                    className="w-full h-[360px] rounded-md shadow-lg"
                  />
                </div>
                <div>
                  <img
                    src="../image/app6.png"
                    alt="..."
                    className="w-full h-[360px] rounded-md shadow-lg"
                  />
                </div>
                <div>
                  <img
                    src="https://peselectricals.com/img/1205m8.jpg"
                    alt="..."
                    className="w-full h-[360px] rounded-md shadow-lg"
                  />
                </div>
                <div>
                  <img
                    src="https://peselectricals.com/img/5648mile1.jpg"
                    alt="..."
                    className="w-full h-[360px] rounded-md shadow-lg"
                  />
                </div>
                <div>
                  <img
                    src="https://peselectricals.com/img/1557mile3.jpg"
                    alt="..."
                    className="w-full h-[360px] rounded-md shadow-lg"
                  />
                </div>
              </Carousel>
            </div>
          )}
        </div>

        <div class="bg-neutral-300 p-4 text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200">
          © 2023 Copyright :
          <a class="dark:text-neutral-400" href="/">
            <u> PES Electrical Pvt.Ltd </u>
          </a>
        </div>
      </footer> */}
      <div class=" bg-gray-900 mt-5">
        <div class="mx-auto text-white py-10">
          <div class="text-center">
            <h3 class="text-3xl mb-3"> Download our PES VR App </h3>
            <p> We will send you the Best Price </p>
            <div class="flex justify-center my-10">
              <div class="flex items-center border w-auto rounded-lg px-4 py-2 w-52 mx-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/888/888857.png"
                  class="w-7 md:w-8"
                  alt="..."
                />
                <Link to="https://play.google.com/store/apps/details?id=com.pes.vr">
                  <div class="text-left ml-3 cursor-pointer">
                    <p class="text-xs text-gray-200">Download on </p>
                    <p class="text-sm md:text-base cursor-pointer">
                      Google Play Store
                    </p>
                  </div>
                </Link>
              </div>
              <div class="flex items-center border w-auto rounded-lg px-4 py-2 w-44 mx-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/888/888841.png"
                  class="w-7 md:w-8"
                  alt="..."
                />
                <Link to="https://apps.apple.com/in/app/pes-vr/id1670383236">
                  <div class="text-left ml-3 cursor-pointer">
                    <p class="text-xs text-gray-200">Download on </p>
                    <p class="text-sm md:text-base"> Apple Store </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="mt-10 w-3/5 mx-auto flex flex-col md:flex-row md:justify-between items-center text-sm text-gray-400">
            <Link to="/">
              <p class="order-2 md:order-1 mt-8 md:mt-0">
                &copy; <u> PES Electrical Pvt.Ltd </u>
              </p>
            </Link>
            <div class="order-1 md:order-2">
              <Link to="/">
                <span class="px-2">Home</span>
              </Link>
              <Link to="/about">
                <span class="px-2 border-l">About Us</span>
              </Link>
              <Link to="/product">
                <span class="px-2 border-l">Products</span>
              </Link>
              <Link to="/service">
                <span class="px-2 border-l">AMC Service</span>
              </Link>
              <Link to="/contactus">
                <span class="px-2 border-l">Contact Us</span>
              </Link>
              <Link to="/privacy-policy">
                <span class="px-2 border-l">Privacy & Policy 1</span>
              </Link>
              <Link to="/privacypolicy">
                <span class="px-2 border-l">Privacy & Policy 2</span>
              </Link>
              <Link to="/privacyPolicy">
                <span class="px-2 border-l">Privacy & Policy 3</span>
              </Link>
              <Link to="/privacy">
                <span class="px-2 border-l">Privacy & Policy 4</span>
              </Link>
              <Link to="/termsandconditions">
                <span class="px-2 border-l">Terms & Conditions</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
